import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Floor } from '../../models';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getIsSubmitLoading = (state: State) => state.isSubmitLoading;

export const getFloors = (state: State) => state.floors;

export const getTableauNumbersWithoutFloor = (state: State) =>
  state.tableauNumbersWithoutFloor;

export const selectState = createFeatureSelector<State>('floors');

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectIsSubnmitLoading = createSelector(
  selectState,
  getIsSubmitLoading,
);

export const selectFloors = createSelector(selectState, getFloors);

export const selectTableauNumbersWithoutFloor = createSelector(
  selectState,
  getTableauNumbersWithoutFloor,
);

export const selectFloorsByPropertyId = (propertyId: number) =>
  createSelector(selectFloors, (data) => data && data[propertyId]);

export const selectTableauNumbersWithoutFloorByPropertyId = (
  propertyId: number,
) =>
  createSelector(
    selectTableauNumbersWithoutFloor,
    (data) => data && data[propertyId],
  );
