import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadSuccess,
    (state, { floors, tableauNumbersWithoutFloor }) => ({
      ...state,
      isLoading: false,
      error: null,
      floors,
      tableauNumbersWithoutFloor,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.createRequest, (state) => ({
    ...state,
    isSubmitLoading: true,
    error: null,
  })),
  on(fromActions.createSuccess, (state, { item }) => ({
    ...state,
    isSubmitLoading: false,
    error: null,
  })),
  on(fromActions.createFailure, (state, { error }) => ({
    ...state,
    isSubmitLoading: false,
    error,
  })),

  on(fromActions.updateRequest, (state) => ({
    ...state,
    isSubmitLoading: true,
    error: null,
  })),
  on(fromActions.updateSuccess, (state, { item }) => ({
    ...state,
    isSubmitLoading: false,
    error: null,
  })),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    isSubmitLoading: false,
    error,
  })),

  on(fromActions.deleteRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.deleteSuccess, (state, { floorId }) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.deleteFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.orderRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.orderSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.orderFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.orderFloorTableauNumbersRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.orderFloorTableauNumbersSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(fromActions.orderFloorTableauNumbersFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.syncRequest, (state) => ({
    ...state,
    isSubmitLoading: true,
    error: null,
  })),
  on(fromActions.syncSuccess, (state) => ({
    ...state,
    isSubmitLoading: false,
    error: null,
  })),
  on(fromActions.syncFailure, (state, { error }) => ({
    ...state,
    isSubmitLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function floorsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
