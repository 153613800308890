import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { FloorsService } from '../../services/floors.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class FloorsStoreEffects {
  constructor(
    private dataService: FloorsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertiesIds }) =>
        this.dataService.load(propertiesIds).pipe(
          map(({ data }) =>
            fromActions.loadSuccess({
              floors: data.floors,
              tableauNumbersWithoutFloor: data.tableau_numbers_without_floor,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createRequest),
      switchMap(({ request, onSuccess }) =>
        this.dataService.create(request).pipe(
          map(({ data }) => {
            if (onSuccess) {
              onSuccess();
            }
            this.notifications.createSuccess('floor');
            return fromActions.createSuccess({
              item: data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ request, onSuccess }) => {
        return this.dataService.update(request).pipe(
          map(({ data }) => {
            if (onSuccess) {
              onSuccess();
            }
            this.notifications.updateSuccess('floor');
            return fromActions.updateSuccess({
              item: data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateFailure(error));
          }),
        );
      }),
    ),
  );

  order$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.orderRequest),
      switchMap(({ request }) =>
        this.dataService.order(request).pipe(
          map(() => {
            return fromActions.orderSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.orderFailure(error));
          }),
        ),
      ),
    ),
  );

  orderTableauNumbers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.orderFloorTableauNumbersRequest),
      switchMap(({ request }) =>
        this.dataService.orderTableauNumbers(request).pipe(
          map(() => {
            return fromActions.orderFloorTableauNumbersSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.orderFloorTableauNumbersFailure(error));
          }),
        ),
      ),
    ),
  );

  sync$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.syncRequest),
      switchMap(({ request, onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
        return this.dataService.sync(request).pipe(
          map(() => {
            this.notifications.updateSuccess('floor');
            return fromActions.syncSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.syncFailure(error));
          }),
        );
      }),
    ),
  );

  deleteTableauNumberId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteTableauNumbersRequest),
      switchMap(({ floor, tableuNumberId, propertyId }) => {
        const tableau_number_id: number[] = floor.tableau_numbers
          .map(({ id }) => +id)
          .filter((id) => id !== tableuNumberId);

        return this.dataService
          .sync({
            tableau_number_id,
            property_id: propertyId,
            property_floor_id: floor.id,
          })
          .pipe(
            map(() => {
              this.notifications.updateSuccess('floor');
              return fromActions.syncSuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.syncFailure(error));
            }),
          );
      }),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ propertyId, floorId }) =>
        this.dataService.delete(propertyId, floorId).pipe(
          map(() => {
            this.notifications.deleteSuccess('floor');
            return fromActions.deleteSuccess({
              floorId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );
}
