import { Floor, FloorTableauNumber } from '../../models';

export interface State {
  isLoading?: boolean;
  isSubmitLoading?: boolean;
  error?: any;
  floors: { [propertyId: number]: Floor[] };
  tableauNumbersWithoutFloor: { [propertyId: number]: FloorTableauNumber[] };
}

export const initialState: State = {
  isLoading: false,
  isSubmitLoading: false,
  error: null,
  floors: null,
  tableauNumbersWithoutFloor: null,
};
