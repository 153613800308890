import { createAction, props } from '@ngrx/store';

import {
  Floor,
  FloorRequest,
  FloorTableauNumber,
  OrderFloorRequest,
  OrderFloorTableauNumbersRequest,
  SyncFloorRequest,
} from '../../models';

export const loadRequest = createAction(
  '[Floors] Load Request',
  props<{
    propertiesIds: number[];
  }>(),
);
export const loadSuccess = createAction(
  '[Floors] Load Success',
  props<{
    floors: { [propertyId: number]: Floor[] };
    tableauNumbersWithoutFloor: { [propertyId: number]: FloorTableauNumber[] };
  }>(),
);
export const loadFailure = createAction(
  '[Floors] Load Failure',
  props<{ error: any }>(),
);

export const createRequest = createAction(
  '[Floors] Create Request',
  props<{
    request: FloorRequest;
    onSuccess?: () => void;
  }>(),
);
export const createSuccess = createAction(
  '[Floors] Create Success',
  props<{ item: Floor }>(),
);
export const createFailure = createAction(
  '[Floors] Create Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Floors] Update Request',
  props<{
    request: FloorRequest;
    onSuccess?: () => void;
  }>(),
);
export const updateSuccess = createAction(
  '[Floors] Update Success',
  props<{ item: Floor }>(),
);
export const updateFailure = createAction(
  '[Floors] Update Failure',
  props<{ error: any }>(),
);

export const orderRequest = createAction(
  '[Floors] Order Request',
  props<{
    request: OrderFloorRequest;
  }>(),
);
export const orderSuccess = createAction('[Floors] Order Success');
export const orderFailure = createAction(
  '[Floors] Order Failure',
  props<{ error: any }>(),
);
export const orderFloorTableauNumbersRequest = createAction(
  '[Floors] Order Floor Tableau Numbers Request',
  props<{
    request: OrderFloorTableauNumbersRequest;
  }>(),
);
export const orderFloorTableauNumbersSuccess = createAction(
  '[Floors] Order Floor Tableau Numbers Success',
);
export const orderFloorTableauNumbersFailure = createAction(
  '[Floors] Oder Floor Tableau Numbers Failure',
  props<{ error: any }>(),
);

export const syncRequest = createAction(
  '[Floors] Sync Request',
  props<{
    request: SyncFloorRequest;
    onSuccess?: () => void;
  }>(),
);
export const syncSuccess = createAction('[Floors] Sync Success');
export const syncFailure = createAction(
  '[Floors] Sync Failure',
  props<{ error: any }>(),
);

export const deleteTableauNumbersRequest = createAction(
  '[Floors] Delete Tableau Numbers Request',
  props<{
    floor: Floor;
    propertyId: number;
    tableuNumberId: number;
  }>(),
);
export const deleteTableauNumbersSuccess = createAction(
  '[Floors] Delete Tableau Numbers Success',
);
export const deleteTableauNumbersFailure = createAction(
  '[Floors] Delete Tableau Numbers Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Floors] Delete Request',
  props<{
    propertyId: number;
    floorId: number;
  }>(),
);
export const deleteSuccess = createAction(
  '[Floors] Delete Success',
  props<{ floorId: number }>(),
);
export const deleteFailure = createAction(
  '[Floors] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Floors] Reset State');
